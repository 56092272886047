import React from "react"

import "./Footer.scss"

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <div className="container">
        <div className="row reversible">
          <div className="col-md-5">
            <div className="footer-menu">
              <ul>
                <li><a href="/startups">Startups</a></li>
                <li><a href="/partners">Partners</a></li>
                <li><a href="/insights">Insights</a></li>
                {/* <li><a href="/team">Team</a></li> */}
                {/* <li>
                  <a 
                    href="https://www.vaporware.net/careers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <div className="footer-cta">
              <p><strong>Got a great idea?</strong></p>
              <p>Vaporware actively works on 3 startups at a time. Let's talk to see if we can work together next. </p>
              <a href="/chat" className="button-contact">Let's chat</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="footer-info">
      <div className="container">
        <div className="footer-grid">
          <div className="contact">
            <span className="mr-3"><a href="mailto:hello@vaporware.net">hello@vaporware.net</a></span>
            {/* <span><a href="tel:1-919-827-4061">(919) 827-4061</a></span> */}
          </div>
          <div className="social">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/vaporware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/vpware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://dribbble.com/vaporware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Dribbble
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/vaporware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
              </li>
            </ul> 
          </div>
          <div className="address">
          <a
            href="https://g.page/vaporware-raleigh"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <span>400 W. Morgan St #204<span className="d-none d-md-inline">, </span></span> */}
            <span>Raleigh, NC</span>
          </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
