import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import Footer from "src/components/new/Footer"
import SEO from "src/components/SEO"
import "./Layout.scss"

const NewLayout = ({ meta: {title, image, description, path}, children, blueHeader, bodyClass, location}) => {
  
  const canonical = path ? `https://www.vaporware.net${path}` : location?.href
  
  const [menuOpen, setMenuOpen] = useState(false)
  const [showScrollButton, setShowScrollButton] = useState(false)

  const getTransitionPoint = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const footer = document.getElementsByTagName("footer")[0]
    const rect = footer.getBoundingClientRect()
    return rect.top + window.scrollY - vh + 116 // dial it in
  }
  
  useEffect(() => {

    const handleScrollButton = () => {
      const transitionPoint = getTransitionPoint()
      if(window.scrollY > transitionPoint) {
        setShowScrollButton(true)
      } else {
        setShowScrollButton(false)
      }
    }
   
    window.addEventListener("scroll", handleScrollButton)

    return () => {
      window.removeEventListener("scroll", handleScrollButton)
    }
  }, [])
  
  const openMenu = () => {
    setMenuOpen(true)
    document.body.classList.add("locked")
  }

  const closeMenu = () => {
    setMenuOpen(false)
    document.body.classList.remove("locked")
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }
  
  return (
    <div className="new-layout">
      <Helmet
        bodyAttributes={{
          class: bodyClass,
        }}
      />
      <SEO
        title={title}
        description={description}
        canonical={canonical}
      />
      <main>
        <div className="menu-overlay"></div>
        {children}
      </main>
      <button 
        className={`menu-button ${showScrollButton ? "d-none" : ""}`}
        onClick={openMenu}
      >
        Menu
      </button>
      <Footer />
      <div className={`menu ${menuOpen ? "open" : ""}`}>
        <Footer />
        <button 
          className="menu-button close-button"
          onClick={closeMenu}
        >
          Close
        </button>
      </div>
      <button 
        className={`scroll-button ${showScrollButton ? "active" : ""}`}
        onClick={scrollToTop}
      >
        Back to top
      </button>
    </div>
  );
}

NewLayout.defaultProps = {
  blueHeader: false,
  meta: {
    title: "",
    image: {
      childImageSharp: {
        gatsbyImageData: {}
      },
    },
    description: "",
  },
  bodyClass: undefined
}

NewLayout.propTypes = {
  meta: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }),
  }),
  children: PropTypes.node.isRequired,
  blueHeader: PropTypes.bool,
  bodyClass: PropTypes.string,
  location: PropTypes.object
}


export default NewLayout
