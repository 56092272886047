import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import LandingForm from "src/components/forms/Landing"
// import InsightCarousel from "src/components/InsightCarousel"
import Content from "src/components/Content"

import "./landingTemplate.scss"

const LandingTemplate = ({data: {page: {frontmatter: {
  meta, hero, main, form,
}, html}} }) => (
  <Layout meta={meta}>
    <Header>
      <h1>{hero.title}</h1>
      <h1>{hero.subtitle}</h1>
    </Header>
    <div className="landing-template">
      <div className="landing-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              {main &&
                <>
                  <h2>{main.title}</h2>
                  <Content html={main.description} />
                </>
              }
              <LandingForm formConfig={form} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LandingTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
      frontmatter {
        main {
          title
          description
        }
        form {
          name
          fields
          columns
          buttonLabel
        }
      }
    }
  }
`
