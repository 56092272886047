/* eslint-disable react/no-danger */
import React from "react"
import remark from "remark"
import remarkHtml from "remark-html"
import PropTypes from "prop-types"

const Content = ({html, className}) => {
  // Convert markdown in frontmatter fields
  const content = remark()
    .use(remarkHtml)
    .processSync(html)
    .toString()

  return (
    <div 
      dangerouslySetInnerHTML={{ __html: content }}
      className={className}
    />
  )
}

Content.defaultProps = {
  html: "",
  className: "",
}

Content.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
}

export default Content
