/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"

import Logo from "src/components/Logo"
import "./Header.scss"

const Header = ({ children, blue }) => {

  return (
    <header className="new-header">
      <div className={`hero ${blue ? "blue" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Logo className="navbar-brand" isBlue={!blue} />
              {children}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  blue: false,
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  blue: PropTypes.bool,
}

export default Header
